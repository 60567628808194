/*Tablet and above*/
/* 688px / 16 = 43em */
.mob {
	display: block;
}
.desk {
	display: none;
}

@media (min-width: 43em) {
	.desk {
		display: block;
	}
	.mob {
		display: none;
	}
}
/* 1440 / 16 = 82em */
/*Desktop and Above*/
@media (min-width: 82em) {
}
/*Larger screens*/
@media (min-width: 120em) {
}

/* Bold - 300 */
@font-face {
	font-family: "Avant Garde";
	src: local("Avant Garde"),
		url(./assets/fonts/ITCAvantGardeStd-Bk.otf) format("opentype");
	font-weight: 300;
}
/* Medium - 500 */
@font-face {
	font-family: "Avant Garde";
	src: local("Avant Garde"),
		url(./assets/fonts/ITCAvantGardeStd-Md.otf) format("opentype");
	font-weight: 500;
}
/* Bold - 600 */
@font-face {
	font-family: "Avant Garde";
	src: local("Avant Garde"),
		url(./assets/fonts/ITCAvantGardeStd-Demi.otf) format("opentype");
	font-weight: 600;
}

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
	color: #f5f2e5;
	background-color: #23272d;
	font-size: 1em;
	line-height: 1.4;
	font-family: "Avant Garde", sans-serif;
	overflow-x: hidden;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
	resize: vertical;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
	display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	/* 1 */
}

@media print,
	(-webkit-min-device-pixel-ratio: 1.25),
	(min-resolution: 1.25dppx),
	(min-resolution: 120dpi) {
	/* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
	*,
	*::before,
	*::after {
		background: #fff !important;
		color: #000 !important;
		/* Black prints faster */
		box-shadow: none !important;
		text-shadow: none !important;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		box-sizing: border-box;
	}

	a[href]::after {
		content: " (" attr(href) ")";
	}

	abbr[title]::after {
		content: " (" attr(title) ")";
	}

	/*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
	a[href^="#"]::after,
	a[href^="javascript:"]::after {
		content: "";
	}

	pre {
		white-space: pre-wrap !important;
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	/*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
button,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	font-family: "Avant Garde", sans-serif !important;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a:focus {
	text-decoration: none;
}
textarea {
	resize: none;
}
button {
	background: none;
	border: none;
	outline: inherit;
}
@media print {
	*,
	*::before,
	*::after {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a,
	a:visited {
		text-decoration: underline;
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}
	pre {
		white-space: pre-wrap !important;
	}
	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid #000;
	}
	.table {
		border-collapse: collapse !important;
	}
	.table td,
	.table th {
		background-color: #fff !important;
	}
	.table-bordered th,
	.table-bordered td {
		border: 1px solid #ddd !important;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;

	background-color: #23272d;
	margin: 0;
	border-radius: 0px;
	overflow-y: auto;
	overflow-x: hidden;
	scroll-behavior: smooth;
	padding: 117px 10px 0px 10px;
	scroll-behavior: smooth;
}
@media (min-width: 43em) {
	/* 688px / 16 = 43em */
	body {
		padding: 117px 20px 20px 20px;
	}
}

#main-wrap,
.root,
.app {
	height: 100%;
}
/* H1 - Constants  */
h1 {
	font-family: "Avant Garde", sans-serif;
	font-weight: 600;
	font-size: calc(112.5% + 9.5vw);
	transition: 1s;
	letter-spacing: -0.022em;
	/* line-height: 0.85em; */
	text-transform: uppercase;
}
h2 {
	font-size: calc(100% + 5.5vw);
	font-weight: 600;
	transition: 1s;

	text-transform: uppercase;
}
h3 {
	font-family: "Avant Garde", sans-serif;
	font-weight: 600;
	font-size: calc(100% + 4.5vw);
	transition: 1s;

	/* line-height: 0.85em; */
	text-transform: uppercase;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #f5f2e5;
	margin: 1em 0;
	padding: 0;
}

p {
	font-size: calc(100% + 0.1vw);

	line-height: 23px;

	font-weight: 500;
}
p a {
	color: #afafaf;
	text-decoration: none;
}

caption {
	font-weight: 600;
	font-size: 27px;
	line-height: 103.1%;
	/* letter-spacing: -0.022em; */
	text-transform: uppercase;
	color: #f5f2e5 !important;
}
sub {
	font-weight: 500;
	line-height: 103.1%;
	font-size: calc(87% + 0.5vw);
	text-transform: uppercase;
	/* letter-spacing: -0.022em; */
}

.overlay:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(35, 39, 45, 0.4);
	opacity: 0.7;
	z-index: 1;
}

.w-100 {
	font-weight: 100;
}

.sub {
	color: #afafaf !important;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
